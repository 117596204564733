<template>
  <div>
    <skeleton-card v-if="initialize" :cards="6"/>
    <b-row class="mt-2" v-else>
      <b-col
        md="6"
        lg="4"
      >
        <b-card
          title="Personal Account"
        >
        <div class="clearfix">
          <table class="table float-left" style="line-height:1;">
            <tr>
              <td class="border-0" style="width:9rem;">Role:</td>
              <th class="border-0">Admin</th>
            </tr>
            <tr>
              <td class="border-0" style="width:9rem;">Balance:</td>
              <th class="border-0">KSH {{ userBalance }}</th>
            </tr>
            <tr>
              <td class="border-0" style="width:9rem;">Account Type:</td>
              <th class="border-0">
                <span v-if="user.postpaid_personal_account">Postpaid</span>
                <span v-else>Prepaid</span>
              </th>
            </tr>
            <tr>
              <td class="border-0" style="width:9rem;">Registered At:</td>
              <th class="border-0">{{user.format_created_at}}</th>
            </tr>
          </table>

          <b-button
            variant="success"
            class="float-right mt-2 unclickable-btn shadow-none"
            v-if="currentAccount.PersonalAccount == 'true'"
          >
            Active
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="float-right mt-2"
            :disabled="isLoading"
            @click="switchAccount('', '', true)"
            v-else
          >
            Switch
          </b-button>
        </div>
        </b-card>
      </b-col>

      <b-col
        md="6"
        lg="4"
        v-for="account in accounts"
        :key="account.ID"
      >
        <b-card
          :title="account.first_name + ' ' + account.last_name"
        >
        <div class="clearfix">
          <table class="table float-left" style="line-height:1;">
            <tr>
              <td class="border-0" style="width:9rem;">Role:</td>
              <th class="border-0">{{ account.title }}</th>
            </tr>
            <tr>
              <td class="border-0" style="width:9rem;">Balance:</td>
              <th class="border-0">KSH {{ account.balance }}</th>
            </tr>
            <tr>
              <td class="border-0" style="width:9rem;">Account Type:</td>
              <th class="border-0">
                <span v-if="account.postpaid">Postpaid</span>
                <span v-else>Prepaid</span>
              </th>
            </tr>
            <tr>
              <td class="border-0" style="width:9rem;">Registered At:</td>
              <th class="border-0">{{account.format_created_at}}</th>
            </tr>
          </table>

          <b-button
            variant="success"
            class="float-right mt-2 unclickable-btn shadow-none"
            v-if="currentAccount.TeamId == account.ID"
          >
            Active
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="float-right mt-2"
            :disabled="isLoading"
            @click="switchAccount(account.ID, account.role_id, false)"
            v-else
          >
            Switch
          </b-button>
        </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BCardText,
  BFormGroup,
  BButton
} from "bootstrap-vue";

import SkeletonCard from "@/views/pages/shimmers/SkeletonCard.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import { getUserData, getUserBalance, getUserAccount } from "@/auth/utils";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCol,
    BRow,
    BCard,
    BCardText,
    BFormGroup,
    BButton,
    SkeletonCard,
  },
  data(){
    return{
      initialize: true,
      isLoading: false,
      user: null,
      userBalance: null,
      currentAccount: null,
      accounts: [],
    };
  },
  methods:{
    switchAccount(teamId, roleId, personalAccount){
      this.isLoading = false;
      let reqBody

      if (teamId == "" || roleId == "") {
        reqBody = {
          personal_account: personalAccount,
        };
      } else {
        reqBody = {
          team_id: teamId,
          role_id: roleId,
          personal_account: personalAccount,
        };
      }
      this.$http
        .post("/team/accounts/switch", reqBody)
        .then((response) => {
              let userData = this.user;

              let res = response.data.data;

              userData.postpaid = res.postpaid;

              useJwt.setToken(res.token);

              localStorage.setItem("userData", JSON.stringify(userData));

              localStorage.setItem("userAccount", JSON.stringify({
                TeamId: res.team_id,
                Account: res.account,
                RoleId: res.role_id,
                Role: res.role,
                PersonalAccount:res.personal_account
              }));

              localStorage.setItem("balance", res.balance);

              this.currentAccount = getUserAccount();

              this.$root.$emit("account-switched");

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: res.message,
                  icon: "LogInIcon",
                  variant: "success",
                },
              });


              this.isLoading = false;

          })
          .catch(error => {
               for (let err of error.response.data.error) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title:err,
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                }
                this.isLoading = false;
            })
    }
  },
  created() {
    this.user = getUserData();
    this.userBalance = getUserBalance();
    this.currentAccount = getUserAccount();

    this.$http
      .get("/team/accounts")
      .then((response) => {
        this.accounts   = response.data.data;
        this.initialize = false;
      });
  },
};
</script>

<style scoped>
.unclickable-btn:hover,.unclickable-btn:focus, .unclickable-btn{
  cursor: default !important;
}
</style>