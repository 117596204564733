<template>
  <b-row >
    <b-col md="6" lg="4" v-for="card in cards" :key="card" class="mt-2">
      <div class="shimmer-card">
        <div class="p-32">
          <div class="line shimmer-heading"></div>

          <div class="d-flex align-item-center justify-content-start mt-2">
            <div class="line shimmer-text mt-2"></div>
            <div class="line shimmer-text mt-2 ml-3"></div>
          </div>

          <div class="d-flex align-item-center justify-content-start">
            <div class="line shimmer-text mt-2"></div>
            <div class="line shimmer-text mt-2 ml-3"></div>
          </div>

          <div class="d-flex align-item-center justify-content-start">
            <div class="line shimmer-text mt-2"></div>
            <div class="line shimmer-text mt-2 ml-3"></div>
          </div>

          <div class="d-flex align-item-center justify-content-start">
            <div class="line shimmer-text mt-2"></div>
            <div class="line shimmer-text mt-2 ml-3"></div>
          </div>

          <div class="d-flex align-item-center justify-content-end">
            <button
              class="mr-50 mt-2 btn line shimmer-btns"
            ></button>
          </div>
          
        </div>
      </div>
    </b-col>
  </b-row>
  
</template>

<script>
import { BCard, BCol, BRow, BButton } from "bootstrap-vue";

export default {
  props: ["cards"],

  components: {
    BCard,
    BCol,
    BRow,
    BButton,
  },
};
</script>

<style lang="scss" scoped>
.shimmer-card {
  position: relative;
  -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  background-color: #FFF;
  border-radius: 6px;
  height: 290px;
  overflow: hidden;


  .p-32 {
      padding: 20px;
  }
}

$base-color: #f4f4f4;
$shine-color: rgba(229, 229, 229, 0.8);
$animation-duration: 2s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    $base-color 0px,
    $shine-color 40px,
    $base-color 80px
  );
  background-size: 600px;
}

.line {
  border-radius: 7px;

  @include background-gradient;
  animation: shine-lines $animation-duration infinite ease-out;
}

.shimmer-heading{
  width: 70%;
  height: 24px;

}

.shimmer-text {
  width: 30%;
  height: 8px;
}

.shimmer-btns {
  width: 120px;
  height: 36px;
}


@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 140px;
  }
}
</style>